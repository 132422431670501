import { EditingRoomStoreActions, EditingRoomStoreState } from './EditingRoomStoreApi';
import { create } from 'zustand';
import { IRoom } from '../../interfaces/room';
import { indexeddbObject } from '../../helpers/indexeddbObject';
import { useAuthStore } from '../auth/AuthStore';
import { UserId } from '@chessclub/grpc_wrapper/src/api';

type EditingRoomStore = EditingRoomStoreActions & EditingRoomStoreState;

const editingRoomDb = indexeddbObject<IRoom>('editing-room');

export const useEditingRoomStore = create<EditingRoomStore>((
  set,
  get
) => {

  const privateState: {userId: UserId} = {userId: null};

  useAuthStore.subscribe(async (state, prev) => {
    if (prev.user !== state.user && state.user?.id) {
      privateState.userId = state.user?.id;
      set({editingRoom: await editingRoomDb.get(privateState.userId)})
    }
  });

  return {
    editingRoom: null,

    async startEdit(room?: IRoom) {
      room = room || {
        userId: privateState.userId,
        name: "new room",
        description: "",
        isActive: false,
        customData: {
          demonstration: null,
        },
        settings: {
          toolbar: true,
          tasks: true,
          moves: true,
          chat: true,
          cameras: true,
          captcha: true,
          footer: true,
          menu: true,
          profile: true,
        },
        students: []
      }
      await editingRoomDb.put(privateState.userId, room);
      set({editingRoom: room})
    },

    async updateEditingRoom(roomPart: Partial<IRoom>) {
      const editingRoom = get().editingRoom;
      const newRoom = {...editingRoom, ...roomPart};
      set({editingRoom: newRoom})
      await editingRoomDb.put(privateState.userId, newRoom);
    },

    async cancelEdit() {
      await editingRoomDb.del(privateState.userId);
      set({editingRoom: null})
    },
  }
})