import React from "react";
import { RawSvg } from "../../../../../../../components/Icon/RawSvg";
import bgw from '../../../../../../../svg/images/human-outline-white.svg'
import bgb from '../../../../../../../svg/images/human-outline.svg'
import { Theme, useThemeStore } from '../../../../../../../store/themeStore';

export function CameraCardBackground() {
  return <div style={{display: "flex"}}>
    <RawSvg
      rawSvg={bgb}
      rawSvgDark={bgw}
      style={{ width: "100%" }}
    />
  </div>
}