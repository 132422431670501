import { useLocalization } from '../../../l10n/UseLocalization';
import { useMaterialsStore } from '../../../store/materials/MaterialsStore';
import { useEditingTopicStore } from '../../../store/editing-topic/EditingTopicStore';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import { ToolbarButton } from '../../../components/ContentContainer/ToolbarButton';
import { KnownIcons } from '../../../components/Icon/KnownIcons';
import { MaterialsMode } from '../../../store/materials/MaterialsStoreApi';
import React from 'react';
import { TopicType } from '@chessclub/grpc_wrapper/src/api/types/ITopic';
import { newMaterial } from '../newMaterial';
import { newMaterialTopic } from '../newMaterialTopic';

export function MaterialsPageToolbarVariant() {

  const lang = useLocalization();

  const { toggleMode, mode, selectedTopic } = useMaterialsStore();
  const { startEditTopic } = useEditingTopicStore()
  const { startEditTask } = useEditingTaskStore()

  return <>

    <ToolbarButton
      icon={KnownIcons.question_in_rect} text={'Materials'}
      active={mode !== MaterialsMode.KNOWLEDGE}
      onClick={() => toggleMode(MaterialsMode.MATERIALS)}
    />
    <ToolbarButton
      icon={KnownIcons.chat} text={'Knowledge'}
      active={mode !== MaterialsMode.MATERIALS}
      onClick={() => toggleMode(MaterialsMode.KNOWLEDGE)}
    />

    {/*<ToolbarButton*/}
    {/*  icon={KnownIcons.plus}*/}
    {/*  text={'Topic'}*/}
    {/*  onClick={() => startEditTopic(newMaterialTopic())}*/}
    {/*/>*/}
    {/*<ToolbarButton*/}
    {/*  icon={KnownIcons.plus}*/}
    {/*  text={'Material'}*/}
    {/*  onClick={() => startEditTask(newMaterial())}*/}
    {/*  disabled={selectedTopic?.type === TopicType.KNOWLEDGE}*/}
    {/*/>*/}

  </>;
}