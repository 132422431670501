import React, { useState } from 'react';
import { Agreement, InputWrapper } from './common';
import Input from '../../components/low-level-components/Input';
import Checkbox from '../../components/low-level-components/Checkbox';
import { useAuthStore } from '../../store/auth/AuthStore';
import { IProfile } from '@chessclub/grpc_wrapper';
import styled from 'styled-components';


const AgreementWrapper = styled.div`
  padding: 0px;
  margin: 30px 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;

  & > *:first-child {
    padding: 0px;
    margin-top: 3px;
    margin-right: 10px;
  }
`;

export interface IProfileForm {
  profile: IProfile;
  onChangeCallback: () => void | null;
}

function ProfileForm(props: IProfileForm) {
  let { profile, onChangeCallback } = props;
  const { user } = useAuthStore();

  const [state, setState] = useState(null);

  const onChange = (fieldName, event) => {
    profile[fieldName] = event.target.value || undefined;
    setState(Math.random());
    onChangeCallback && onChangeCallback();
  };

  return (
    <div>

      <InputWrapper>
        <Input
          label='Имя'
          value={profile.firstName || ''}
          onChange={onChange.bind(null, 'firstName')}
          fullWidth
        />
      </InputWrapper>

      <InputWrapper>
        <Input
          label='Фамилия'
          value={profile.lastName || ''}
          onChange={onChange.bind(null, 'lastName')}
           fullWidth
        />
      </InputWrapper>

      <InputWrapper>
        <Input
          label='День рождения'
          value={profile.dateOfBirth || ''}
          onChange={onChange.bind(null, 'dateOfBirth')}
          fullWidth
        />
      </InputWrapper>

      <InputWrapper>
        <Input
          label='Город'
          value={profile.town || ''}
          onChange={onChange.bind(null, 'town')}
          fullWidth
        />
      </InputWrapper>

      <InputWrapper>
        <Input
          label='Электронная почта'
          disabled
          fullWidth
          value={user.email}
        />
      </InputWrapper>

      <AgreementWrapper>
        <Checkbox
          onChange={(e) => {
            profile.sendingMessages = e.target.checked;
            onChangeCallback && onChangeCallback();
            setState(Math.random());
          }}
          checked={profile.sendingMessages}
        />
        <Agreement>Согласен (-на) получать информационные рассылки о новостях сервиса</Agreement>
      </AgreementWrapper>

    </div>
  );
}

export default ProfileForm;
