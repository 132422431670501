import React, { ComponentType, CSSProperties, PropsWithChildren, ReactNode, useRef } from 'react';
import styled from 'styled-components';
import { useModalStore } from '../../store/modalStore';

type PopupMenuParams = PropsWithChildren<{
  disabled?: boolean;
  popupContent: ComponentType;
}>;

export function PopupMenu(props: PopupMenuParams) {
  const id = useRef(Math.random());
  const anchorRef = useRef();
  const {openPopup, closePopup, popup} = useModalStore();
  const style: CSSProperties = props.disabled ? {opacity: 0.5, pointerEvents: "none"}: {};

  function toggle(e) {
    // debugger
    if (popup?.id === id.current) {
      closePopup()
    } else {
      e.stopPropagation()
      e.preventDefault()
      props.popupContent && openPopup(id.current, <props.popupContent />, anchorRef.current);
    }
  }

  return (
    <div ref={anchorRef} style={style} onClick={toggle}>
        {props.children}
    </div>
  );
}

