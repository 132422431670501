import React, { useMemo, useState } from 'react';
import { randomFen } from '../../../../logic/randomFen';
import { Stack } from '../../../../components/low-level-components/Stack';
import { FenButtonRaw } from './FenButtonRaw';
import { SvgImage } from '../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../components/Icon/KnownIcons';
import styled from 'styled-components';
import { ServerApi } from '../../../../transport/ServerApi';
import { IFen } from '@chessclub/grpc_wrapper/src/api';
import { LoadingIndicator } from '../../../../components/low-level-components/LoadingIndicator';
import { useEditingTaskStore } from '../../../../store/editing-task/EditingTaskStore';

const GradientBg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(#fff 10px, #fff0 40px);
`;

const errors : any = {
  3: 'Ошибка в данных',
  601: 'Исходной изображение не найдено',
  602: 'Шахматная доска не найдена',
};

export function RecognizeButton() {

  const [fen, setFen] = useState(randomFen());
  const [loading, setLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>();
  const {updateEditingTaskData, editingTask, requestUpdate} = useEditingTaskStore();

  async function onClick() {

    try {
      setErrorText('');
      const result = await uploadImage();
      const imageAsBase64 = result.toString().split(',').pop();
      setLoading(true);
      const response: IFen = await ServerApi.fenRecognizeService.recognizeFenByImage(imageAsBase64);
      setLoading(false);
      if (typeof response === "string") {
        setErrorText(response);
      } else {
        setFen(response.fen);
        updateEditingTaskData({ fen: response.fen });
        requestUpdate()
      }
      // console.log(response);
    } catch (e) {
      setErrorText(e);
      setLoading(false)
    }
  }

  return <FenButtonRaw
    onClick={onClick}
    fen={fen}
    borderColor={errorText ? 'red' : null}
    active={!!errorText || editingTask.fen === fen}
  >
    <GradientBg>
      {loading ?
        <LoadingIndicator /> :
        <SvgImage icon={KnownIcons.folder} size={40} />
      }
    </GradientBg>
  </FenButtonRaw>;
}

async function uploadImage(): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = () => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(input.files[0]);
    };
    input.click();
  });
}