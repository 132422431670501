import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Theme, useThemeStore } from '../../../store/themeStore';

interface IProps {
  colored?: boolean;

  onClick?(e: React.MouseEvent<HTMLElement | SVGSVGElement> | undefined): void;

  size?: number;
}

// Vars
const undyedColor = '#868686';

const StyledCameraIcon = styled.svg`
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.currentTheme['hover']};
    border-radius: 8px;
  }
`;

// Component
export const CameraIcon: FunctionComponent<IProps> = (props) => {
  // Redux state
  const { theme } = useThemeStore();

  // Hooks
  // const theme = useTheme();

  // Vars
  const { colored, ...restProps } = props;
  const iconColor = colored ? (theme === Theme.dark ? '#EF2C53' : '#FF0000') : undyedColor;

  const undyedPath = (
    <path
      d='M7.89999 20.2857V11.7143C7.89999 10.7675 8.69591 10 9.67777 10H18.5666C19.5486 10 20.3444 10.7675 20.3444 11.7143V14.2857L24.9 12V20L20.3444 17.7143V20.2857C20.3444 21.2325 19.5486 22 18.5666 22H9.67777C8.69591 22 7.89999 21.2325 7.89999 20.2857Z'
      fill={undyedColor}
      stroke={undyedColor}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  );

  // View
  return (
    <StyledCameraIcon
      {...restProps}
      onClick={props.onClick}
      width={props.size}
      height={props.size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ cursor: 'pointer' }}
    >
      {colored ? (
        <>
          <path d='M7 25L25 7' stroke={iconColor} strokeWidth='1.3' strokeLinecap='round' />
          <mask id='mask0_6611_41696' maskUnits='userSpaceOnUse' x='5' y='6' width='22' height='20'>
            <path fillRule='evenodd' clipRule='evenodd' d='M5 6V24L23 6H5ZM27 26L27 8L9 26L27 26Z' fill='#EF9033' />
          </mask>
          <g mask='url(#mask0_6611_41696)'>
            <path
              d='M8 20V12C8 10.8954 8.89543 10 10 10H19C20.1046 10 21 10.8954 21 12V14L25 12V20L21 18V20C21 21.1046 20.1046 22 19 22H10C8.89543 22 8 21.1046 8 20Z'
              fill={iconColor}
              stroke={iconColor}
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
        </>
      ) : (
        undyedPath
      )}
    </StyledCameraIcon>
  );
};

