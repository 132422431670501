import React from 'react';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import { TaskType } from '@chessclub/grpc_wrapper';
import { useEditingTaskStore } from '../../store/editing-task/EditingTaskStore';

const icons  = {
  [TaskType.ENGINE]: KnownIcons.power_button,
  [TaskType.TASK]: KnownIcons.chessboard,
  [TaskType.GAME]: KnownIcons.pacman,
  [TaskType.QUESTION]: KnownIcons.question_in_rect,
}

export function TaskEditTaskTypeSelector() {
  const {editingTask, updateEditingTaskData} = useEditingTaskStore();
  return <div style={{ display: 'flex' }}>
    {Object.entries(icons).map(type => {

      let onClick = () => {

        let task = { type: type[0] as TaskType} as  any;
        if (task.type === TaskType.GAME) {
          task.moves = []
        }
        updateEditingTaskData(task, true);
      };
      return type && <SvgImage
        icon={type[1]}
        key={type[0]}
        withHover
        active={editingTask.type === type[0]}
        onClick={onClick}
      />;
    })}
    <div style={{paddingLeft: 10, font: "var(--font-normal)", color: "var(--color-icon-main)", display: 'flex', alignItems: 'center'}}>
      {editingTask.type}
    </div>
  </div>;
}