import { useAuthStore } from '../../store/auth/AuthStore';
import { useLocalization } from '../../l10n/UseLocalization';
import { Role } from '@chessclub/grpc_wrapper';
import { ActiveRooms } from './ActiveRooms';
import React from 'react';
import { PageHeaderStyledComponents } from './PageHeaderStyledComponents';
import { ActiveRoom } from './ActiveRoom';
import { TeacherLessonStatusPanel } from '../../pages/LessonPage/LessonStatusPanel/TeacherLessonStatusPanel';
import { LessonStatusPanel } from '../../pages/LessonPage/LessonStatusPanel/LessonStatusPanel';

const {
  StyledNavItems,
  StyledNavItem,
} = PageHeaderStyledComponents;

export function NavLinks() {

  const { user } = useAuthStore();
  const lang = useLocalization();

  if (!user || user.role === Role.GUEST)
    return null;

  const isStudent = user.role === Role.STUDENT || user.role === Role.EXTERNAL;

  return <StyledNavItems>

    {user.role === Role.ADMIN && <StyledNavItem
      to='/admin'
      className='withHover'
      $active={document.location.href.includes('/admin')}
    >
      {'Admin panel'}
    </StyledNavItem>}

    {!isStudent && <>
      <StyledNavItem
        $active={document.location.href.includes('/materials')}
        to='/materials'
        className='withHover'
      >
        {lang.lesson.materials}
      </StyledNavItem>

      <StyledNavItem
        $active={document.location.href.includes('/rooms')}
        to='/rooms'
        className='withHover'
      >
        {lang.rooms.rooms}
      </StyledNavItem>
    </>
    }

    <LessonStatusPanel />

  </StyledNavItems>;

}