import styled from 'styled-components';
import { Link } from '../../../components/low-level-components/Link';

const offline = "32px 1fr";
const online = "32px 1fr 96px";
const student = "1fr 32px";

export const LessonStatusPanelWrapper = styled(Link)<{
  $active: boolean;
  $online: boolean;
  $student?: boolean
}>`
  display: grid;  
  grid-template-columns: ${p => p.$student ? student : p.$online ? online : offline};
  max-width: 400px;
  border-radius: 12px;
  background: ${p => p.$active ? 'var(--color-selected) !important' : ''};
  box-sizing: border-box;
  padding: 0 10px;
  height: 46px;
  gap: 10px;

  &:hover {
    background: var(--color-hover);
    cursor: pointer;
  }

  & div {
    display: flex;
    align-items: center;
    font: var(--font-normal);
    color: var(--color-icon-main);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
