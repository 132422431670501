import Input from '../../../components/low-level-components/Input';
import React from 'react';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';

export function TaskEditFenInput() {

  const { updateEditingTaskData, editingTask, requestUpdate } = useEditingTaskStore();

  return <Input
    fullWidth
    label={'FEN'}
    value={editingTask.fen}
    onChange={(e) => {
      updateEditingTaskData({ fen: e.target.value, moves: [], tools: {} });
      requestUpdate();
    }}
  />;
}