import { QuestionBase } from '@chessclub/grpc_wrapper';

export function compareTasks(a: QuestionBase, b: QuestionBase) {
  if (a.fen !== b.fen) return false;
  // if (a.pgn !== b.pgn) return false;
  // if (a.tools !== b.tools) return false;
  let ac = a.customData || {} as any,
    bc = b.customData || {} as any;
  if (ac.timerSettingOn !== bc.timerSettingOn) return false;
  if (ac.rulesSettingOn !== bc.rulesSettingOn) return false;
  if (ac.boardLabels !== bc.boardLabels) return false;
  if (ac.highlightMovesSettingOn !== bc.highlightMovesSettingOn) return false;
  return true; // equal
}