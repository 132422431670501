import { ToolbarButton } from '../../components/ContentContainer/ToolbarButton';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import React from 'react';
import { useEditingRoomStore } from '../../store/editing-room/EditingRoomStore';
import { useRoomsStore } from '../../store/rooms/RoomsStore';
import { useAuthStore } from '../../store/auth/AuthStore';
import { emitCentrifugeEvent } from '@chessclub/realtime_infrastructure';
import { makeRoomChannelKey, RoomChannel } from '../../transport/messaging/RoomChannel';
import { ApplyCancelToolbarVariant } from '../ApplyCancelToolbarVariant';

export function RoomEditToolbarVariant() {

  const {  cancelEdit, editingRoom} = useEditingRoomStore();
  const { addRoom, updateRoom} = useRoomsStore();

  async function applyEdit() {
    if (!editingRoom.id) {
      await addRoom(editingRoom);
    } else {
      await updateRoom(editingRoom);
      await emitCentrifugeEvent(makeRoomChannelKey(editingRoom.id),
        RoomChannel.ROOM_SETTINGS_CHANGED, editingRoom.settings);
    }
    await cancelEdit();
  }

  return <ApplyCancelToolbarVariant apply={applyEdit} cancel={cancelEdit}/>

}