import React from 'react';
import { QuestionCard } from '../components/Question/QuestionCard';
import { Castling } from '../../../../components/Castling/Castling';
import { LessonTabs, MovesDisplayMode } from '../../../../store/lesson-ui/LessonUiStoreApi';
import { MovesChain } from '../../../../components/MovesPanel/MovesChain';
import { MovesTable } from '../../../../components/MovesPanel/MovesTable';

import { useLocalization } from '../../../../l10n/UseLocalization';
import { useLessonUiStore } from '../../../../store/lesson-ui/LessonUiStoreImpl';
import { useTaskToDisplay } from '../../../../hooks/useTaskToDisplay';
import { useTasksStore } from '../../../../store/tasks/TasksStore';
import { TextNode } from '../../../../components/low-level-components/TextNode';
import { ChessboardSvg } from '../../../../components/Chessboard/ChessboardSvg';
import { MovesTabCurrentQuestion } from './MovesTabCurrentQuestion';

export function LessonMovesPanel() {
  const lang = useLocalization();
  const { movesMode, setMouseOverStep } = useLessonUiStore();
  const { taskToDisplay } = useTaskToDisplay();
  const { currentTaskGameState } = useTasksStore();
  const moves = currentTaskGameState?.movesState;

  return <>

    <MovesTabCurrentQuestion/>

    <Castling question={taskToDisplay} />

    <div onMouseLeave={() => setMouseOverStep(null)}>
    {
      moves?.length ? movesMode === MovesDisplayMode.CHAIN ?
      <MovesChain moves={moves} /> :
      <MovesTable moves={moves} /> :
      <TextNode style={{ padding: `10px 5px` }}>{lang.lesson.empty}</TextNode>
    }
    </div>
  </>;
}