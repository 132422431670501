import { DemonstrationState, TaskId, UserId, FEN } from '@chessclub/grpc_wrapper';
import {ChannelEvent, ChannelKey, initChannel} from '@chessclub/realtime_infrastructure';
import { RoomSettings } from '@chessclub/grpc_wrapper/src/api/types/IRoom';

export type RoomOnlineStateChanged = {
    isOnline: boolean
}

export interface BoardState  {
    users?: UserId[];
    boardId: TaskId;
    fen: FEN;
    oldFen?: FEN;
    title: string;
}

export type DemonstrationStateMessage = {
    demonstration?: DemonstrationState;
}

export type SetStudentBoardMessage = {
    questionId: TaskId;
    userId: UserId;
}

export type RemoveSelectedStudentsMessage = {
    userIds: UserId[];
}

export type StudentHandUpDownMessage = {
    userId: UserId;
    handUp: boolean;
}

export type StudentBoardChangedMessage = {
    questionId: TaskId;
    userId: UserId;
}

export type AssignedTaskChanged = {
    userIds?: UserId[];
}

export type UserProfileChangedMessage = {
    userId: UserId;
    avatar?: string;
}


export let RoomChannel = {
    ROOM_ONLINE_STATE_CHANGED: new ChannelEvent<RoomOnlineStateChanged>(),
    DEMONSTRATION_STATE_CHANGE: new ChannelEvent<DemonstrationStateMessage>(),
    SET_STUDENT_BOARD: new ChannelEvent<SetStudentBoardMessage>(),
    REMOVE_SELECTED_STUDENTS: new ChannelEvent<RemoveSelectedStudentsMessage>(),
    STUDENT_HAND_UP_DOWN: new ChannelEvent<StudentHandUpDownMessage>(),
    STUDENT_BOARD_CHANGED: new ChannelEvent<StudentBoardChangedMessage>(),
    ASSIGNED_TASKS_CHANGED: new ChannelEvent<AssignedTaskChanged>(),
    ROOM_SETTINGS_CHANGED: new ChannelEvent<RoomSettings>(),
    USER_PROFILE_CHANGED: new ChannelEvent<UserProfileChangedMessage>(),
};

RoomChannel = initChannel(RoomChannel)

export function makeRoomChannelKey(roomId: string): ChannelKey {
    if (roomId === undefined){
        debugger
    }

    return {key: 'ROOM_' + roomId};
}