import React from 'react';
import styled from 'styled-components';
import { SvgIcon, SvgIconProps } from '../SvgIcon';

const StyledIcon = styled.svg<{ withAnimate?: boolean }>`
  ${({ withAnimate }) =>
  withAnimate &&
  ` animation: ping 1s cubic-bezier(0.4, 0, 0.6, 1) 5;

@keyframes ping {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}`}
`;

export function BigHandIcon(props: SvgIconProps & {withAnimate}) {
  return (
    <SvgIcon {...props} colored>
      <path
        className="filled"
        d='M28 0H4C1.79086 0 0 1.79086 0 4V28C0 30.2091 1.79086 32 4 32H28C30.2091 32 32 30.2091 32 28V4C32 1.79086 30.2091 0 28 0Z'
      />
      <path
        stroke="none"
        className="bgFill"
        d='M13.0399 6.939C13.1926 7.11347 13.3333 7.43713 13.3333 8V14.6667H14.6667V6.66667C14.6667 6.30872 14.6776 5.96872 14.8047 5.71481C14.8587 5.60673 14.9303 5.52412 15.0367 5.46267C15.1475 5.39865 15.3384 5.33333 15.6667 5.33333C15.9949 5.33333 16.1859 5.39865 16.2967 5.46267C16.4031 5.52412 16.4747 5.60673 16.5287 5.71481C16.6557 5.96872 16.6667 6.30872 16.6667 6.66667V15.3333H18V8.66667C18 8.1038 18.1407 7.78013 18.2933 7.60567C18.4359 7.44285 18.6551 7.33333 19 7.33333C19.3449 7.33333 19.5641 7.44285 19.7067 7.60567C19.8593 7.78013 20 8.1038 20 8.66667V17.3333L18.9333 18.1333L19.7333 19.2L22.3852 17.2112C23.3789 16.5492 24.1501 16.0932 24.7341 15.8743C25.3477 15.6441 25.4908 15.7669 25.5285 15.8048C25.5829 15.8591 25.6832 16.0835 25.5231 16.676C25.3795 17.2075 25.0811 17.8124 24.768 18.3133L24.7512 18.3401L24.7369 18.3687L24.7328 18.3768L24.712 18.4164C24.6924 18.4533 24.6612 18.5111 24.6168 18.5899C24.5283 18.7473 24.3877 18.9885 24.1847 19.3133C23.7785 19.9631 23.1227 20.9476 22.1333 22.2667C21.8805 22.6037 21.6491 22.9296 21.4321 23.2352L21.4239 23.2468C21.2027 23.5583 20.998 23.8464 20.792 24.1177C20.3811 24.6589 19.9848 25.1065 19.5101 25.4716C18.5857 26.1828 17.2505 26.6667 14.6667 26.6667C9.74409 26.6667 8 23.0092 8 21.3333V10.6667C8 10.4183 8.06665 10.0458 8.23833 9.75967C8.38911 9.50836 8.60608 9.33333 9 9.33333C9.39392 9.33333 9.61089 9.50836 9.76167 9.75967C9.93335 10.0458 10 10.4183 10 10.6667V16H11.3333V8C11.3333 7.43713 11.4741 7.11347 11.6267 6.939C11.7692 6.77619 11.9883 6.66667 12.3333 6.66667C12.6783 6.66667 12.8975 6.77619 13.0399 6.939Z'
      />
    </SvgIcon>
  );
}
