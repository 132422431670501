import React, { useEffect, useRef } from 'react';
import { PeerConnection } from '@chessclub/video-conf/dist/lib/rtc/PeerConnection';

export function CameraVoiceIndication({ pc }: { pc: PeerConnection }) {
  const ref = useRef<SVGPathElement>();
  const frameRef = useRef<number>();


  useEffect(() => {

    let bufferLength = pc?.analyserNode.frequencyBinCount;
    let dataArray = new Uint8Array(bufferLength);
    let value = 0;
    function animate() {
      value = Math.max(0, value - .05);
      pc?.analyserNode.getByteTimeDomainData(dataArray);
      let min = 128, max = 0;
      for (let d in dataArray){
        min = Math.min(dataArray[d], min)
        max = Math.max(dataArray[d], max)
      }
      let currentValue = (max-min-1)/128
      value = Math.max(currentValue, value)
      let s = Math.max(0, value-0.2)*50
      let path = `m0,0 h${s} v2 h-${s*2} v-2 z`;
      ref.current.setAttribute('d', path);
      frameRef.current = requestAnimationFrame(animate);
    }

    frameRef.current = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(frameRef.current);
    };
  }, [pc]);


  return <div style={{ display: 'flex', flexDirection: 'column-reverse', zIndex: 10, pointerEvents: 'none' }}>
    <svg viewBox={'-50 0 100 2'}>
      <path ref={ref} fill={"cyan"}/>
    </svg>
  </div>;
}