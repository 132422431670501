import { Theme, useThemeStore } from '../../../../../../../store/themeStore';
import React from 'react';

export function CameraCardBgLine({forceOn}:{forceOn:boolean}) {
  const {theme} = useThemeStore()
  const background = theme === Theme.dark ?  "#000000" : '#ffffff'
  const className = forceOn ? "" : "hidden";
  return <div
    style={{display: 'flex', flexDirection:'column-reverse'}}>
    <div className={className} style={{height: 37, width: "100%", background, opacity:.5}}/>
  </div>
}