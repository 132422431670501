import React from 'react';
import styled from 'styled-components';
import { useMaterialsStore } from '../../store/materials/MaterialsStore';
import { MaterialsMode } from '../../store/materials/MaterialsStoreApi';
import { HidingScrollbar } from '../../components/low-level-components/HidingScrollbar';
import { ITopic, TopicType } from '@chessclub/grpc_wrapper';
import { TextNode } from '../../components/low-level-components/TextNode';
import { TopicEntry } from '../../parts/MaterialsEditView/TopicEntry';
import { AddTopicElement } from '../../parts/MaterialsEditView/AddTopicElement';
import { newMaterialTopic } from './newMaterialTopic';

const List = styled.div`
  display: flex;
  flex-direction: column;
`;



export function MaterialsPageTopics() {
  const { materialTopics, knowledgeTopics, mode } = useMaterialsStore();
  const showTitle = 1;//knowledgeTopics.length > 0 && materialTopics.length > 0;
  return <HidingScrollbar>
    <List>
      {mode !== MaterialsMode.KNOWLEDGE &&
        <MaterialsPageTopicList canAdd topics={materialTopics} title={showTitle ? 'Мои материалы' : ''} />}
      {mode !== MaterialsMode.MATERIALS &&
        <MaterialsPageTopicList topics={knowledgeTopics} title={showTitle ? 'База занний' : ''} />}
    </List>
  </HidingScrollbar>;
}


export function MaterialsPageTopicList(props: { topics: ITopic[]; title?: string; canAdd?:boolean}) {
  const { setSelectedTopic, selectedTopic, deleteTopic } = useMaterialsStore();
  return <>
    {props.title && <TextNode size={18} style={{padding: "10px 20px"}}>{props.title}</TextNode>}
    {props.canAdd && <AddTopicElement topicFactory={newMaterialTopic}/>}
    {props.topics.length ?
      props.topics.map(topic => <TopicEntry
        selected={selectedTopic?.id === topic.id}
        onClick={() => setSelectedTopic(topic)}
        onDelete={topic.type === TopicType.MATERIAL ?() => deleteTopic(topic) : null}
        topic={topic}
        key={topic.id}
      />) :
      <TextNode style={{padding:10}}>Ничего не найдено</TextNode>
    }
  </>;
}

