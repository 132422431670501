import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Theme, useThemeStore } from '../../../store/themeStore';

interface IProps {
  colored?: boolean;

  onClick?(e: React.MouseEvent<HTMLElement | SVGSVGElement> | undefined): void;

  size?: number;
}

const undyedColor = '#868686';

const StyledMicroIcon = styled.svg`
  border-radius: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.currentTheme['hover']};
    border-radius: 8px;
  }
`;

// Component
export const MicroIcon: FunctionComponent<IProps> = (props) => {
  // Redux state
  const { theme } = useThemeStore();

  // Hooks
  // const theme = useTheme();

  // Vars
  const { colored, ...restProps } = props;
  const iconColor = colored ? (theme === Theme.dark ? '#EF2C53' : '#FF0000') : undyedColor;

  const undyedPath = (
    <>
      <rect
        x='13'
        y='8'
        width='6'
        height='10'
        rx='3'
        fill={undyedColor}
        stroke={undyedColor}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M10 14V15C10 18.3137 12.6863 21 16 21V21C19.3137 21 22 18.3137 22 15V14'
        stroke={undyedColor}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path d='M16 24V21' stroke={undyedColor} strokeWidth='2' strokeLinecap='round' />
    </>
  );

  // View
  return (
    <StyledMicroIcon
      {...restProps}
      onClick={props.onClick}
      width={props.size}
      height={props.size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ cursor: 'pointer' }}
    >
      {colored ? (
        <>
          <path d='M7 25L25 7' stroke={iconColor} strokeWidth='1.3' strokeLinecap='round' />
          <mask id='mask0' maskUnits='userSpaceOnUse' x='5' y='6' width='22' height='20'>
            <path fillRule='evenodd' clipRule='evenodd' d='M5 6V24L23 6H5ZM27 26L27 8L9 26L27 26Z' fill='#27AE60' />
          </mask>
          <g mask='url(#mask0)'>
            <rect
              x='13'
              y='8'
              width='6'
              height='10'
              rx='3'
              fill={iconColor}
              stroke={iconColor}
              strokeWidth='2'
              strokeLinecap='round'
            />
            <path
              d='M10 14V15C10 18.3137 12.6863 21 16 21V21C19.3137 21 22 18.3137 22 15V14'
              stroke={iconColor}
              strokeWidth='2'
              strokeLinecap='round'
            />
            <path d='M16 24V21' stroke={iconColor} strokeWidth='2' strokeLinecap='round' />
          </g>
        </>
      ) : (
        undyedPath
      )}
    </StyledMicroIcon>
  );
};

