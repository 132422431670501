import React from 'react';
import { useEditingTopicStore } from '../../../store/editing-topic/EditingTopicStore';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import { MaterialsPageTopicEditToolbarVariant } from './MaterialsPageTopicEditToolbarVariant';
import { MaterialsPageTaskEditToolbarVariant } from './MaterialsPageTaskEditToolbarVariant';
import { MaterialsPageToolbarVariant } from './MaterialsPageToolbarVariant';

export function MaterialsPageToolbarSelector() {

  const { editingTopic } = useEditingTopicStore();
  const { editingTask } = useEditingTaskStore();

  if (editingTopic)
    return <MaterialsPageTopicEditToolbarVariant />;

  if (editingTask)
    return <MaterialsPageTaskEditToolbarVariant />;

  return <MaterialsPageToolbarVariant />;
}