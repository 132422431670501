import React, { PropsWithChildren, useEffect } from 'react';
import { CameraCardContainer } from '../containers/CameraCardContainer';
import { useLessonStore } from '../../../../../../../store/lesson/LessonStore';
import { useTasksStore } from '../../../../../../../store/tasks/TasksStore';
import { useConferenceStore } from '../../../../../../../store/conferenceStore';
import { useParams } from 'react-router-dom';
import { CameraCardRightPanel } from './CameraCardRightPanel';
import { CameraCardBackground } from './CameraCardBackground';
import { CameraCardVideoTag } from './CameraCardVideoTag';
import { ChessclubUser } from '../../../../../../../interfaces/user';
import { Room } from '@chessclub/video-conf';
import { CameraCardBgLine } from './CameraCardBgLine';
import { CameraCardBottomPanel } from './CameraCardBottomPanel';
import { DndType, useDndStore } from '../../../../../../../store/dndStore';
import { useThemeColors } from '../../../../../../../hooks/useThemeColors';
import { DropZone } from '../../../../../../../components/low-level-components/DropZone';
import { RoomId } from '@chessclub/grpc_wrapper';
import { CameraVoiceIndication } from './CameraVoiceIndication';
import { PeerConnection } from '@chessclub/video-conf/dist/lib/rtc/PeerConnection';

export interface ICameraCard {
  online?: boolean;
  muted?: boolean;
  style?: any;
  showCheckbox?: boolean;
  fixed?: boolean;
  scaled?: boolean;
  onCameraClick?: any;
  user:  ChessclubUser;
  currentUserCard?: boolean;
  blockWrapperRef?: React.MutableRefObject<HTMLDivElement>;
  toggleCameraEnabled(): any;
  toggleMicroEnabled(): any;
  canMove: boolean;
  cameraEnabled: boolean;
  microEnabled: boolean;
  stream?: MediaStream;
  room?: Room;
  mirror?: boolean;
  pc?:PeerConnection;
}

export function CameraCardBase(props: PropsWithChildren<ICameraCard>) {
  const { style, user, room } = props;
  const { accent, red } = useThemeColors();
  const { assignQuestions } = useTasksStore();
  const { demonstrationState, teacherId, isStudentLayout, toggleStudentMonitoringMode, monitoringStudentId} = useLessonStore();
  const { setRoom, setSelectedCameras, selectedCameras } = useConferenceStore();
  const { id: roomId } = useParams<{ id: RoomId }>();
  const selected = !!selectedCameras.find((u) => u.id === user.id);

  // todo / consider about this
  useEffect(() => {
    if (!room) return;
    if (room) setRoom(room);
  }, [room]);

  const handleSelectCameraChange = () => {
    const newSelectedCameras = [...selectedCameras];
    if (!newSelectedCameras.find((u) => u.id === user.id)) {
      newSelectedCameras.push(user);
    } else {
      newSelectedCameras.splice(
        newSelectedCameras.findIndex((u) => u.id === user.id),
        1,
      );
    }
    setSelectedCameras(newSelectedCameras);
  };

  function onClickHandler(e: React.MouseEvent<HTMLDivElement>) {
    if (isStudentLayout) {
      return;
    }
    if (user.id === teacherId) {
      return toggleStudentMonitoringMode(null);
    }
    if (e.ctrlKey || e.metaKey) {
      return handleSelectCameraChange();
    }
    toggleStudentMonitoringMode(user.id);
  }

  async function drop(taskId) {
    await assignQuestions({
      roomId,
      userIds: [props.user.id],
      questionsIds: [taskId],
      socketIds: [],
    });
  }

  // function drag(ev) {
  //   if (!props.currentUserCard && dragEvent?.type === DndType.TASK)
  //     ev.preventDefault()
  // }

  //console.log("user", user.email, ' camera :', user.cameraEnabled, ' mic :', user.microEnabled);

  const borderColor = monitoringStudentId ?
    monitoringStudentId === user.id ?  accent : "transparent" :
    selected ? red : "transparent";


  let canMoveVisible = !isStudentLayout && props.user.id !== teacherId && !!demonstrationState;

  return (
    <CameraCardContainer
      className='CameraCardBase'
      style={{...style}}
      border={borderColor}
      onClick={onClickHandler}
      $canMoveVisible={canMoveVisible}
    >
      <CameraCardBackground/>
      <CameraCardVideoTag {...props} />
      <CameraCardBgLine forceOn={!props.cameraEnabled}/>
      {props.children}
      <CameraCardRightPanel {...props} />
      <CameraCardBottomPanel {...props} canMoveVisible={canMoveVisible}/>
      {props.pc&&<CameraVoiceIndication pc={props.pc}/>}
      {!props.currentUserCard && <DropZone type={DndType.TASK} onDrop={drop}/>}
    </CameraCardContainer>
  );
}

