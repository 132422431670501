import styled from 'styled-components';

export const CameraCardContainer = styled.div<{
    border: string;
    $canMoveVisible: boolean; // ugly
}>`
  box-shadow: inset 0px 0 1px 0px  #777;
  box-sizing: border-box;
  background-color: ${p => p.theme.currentTheme.hover};
  border-left: 3px solid ${p => p.border};
  //border-radius: 4px;
  aspect-ratio: 16/9;
  min-width: 192px;
  overflow: hidden;
  
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  
  & > * {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
  }
  
  & .hidden {
    display: none;
  }
  
  &:hover .hidden {
    display: unset;
  }
    
  & .name-panel {
      grid-column: 1/4;
  }  
  &:hover .name-panel {
      grid-column: ${p => p.$canMoveVisible ? 1 : "1/3" };
  }  
`;
