import React from 'react';
import styled from 'styled-components';
import { IUser } from '@chessclub/grpc_wrapper';
import { TextNode } from '../../components/low-level-components/TextNode';

const Wrapper= styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const User= styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Avatar = styled.img`
  height: 50px;
  border-radius: 50%;
`

export function UsersList(props: {users: IUser[]}) {
  return <Wrapper>
    {props.users.map((u, i) => <UserInList key={i} user={u}/>)}
  </Wrapper>
}

function UserInList(props: {user: IUser}) {
  return <User>
    <Avatar src={props.user.imageUrl} alt="img" />
    <TextNode>{props.user.displayName}</TextNode>
  </User>
}