import React, { useMemo } from 'react';
import { randomFen } from '../../logic/randomFen';
import { FEN } from '@chessclub/grpc_wrapper';
import styled from 'styled-components';
import { ChessboardNotInteractive } from '../Chessboard/ChessboardNotInteractive';
import { Stack } from '../low-level-components/Stack';
import { useUiStore } from '../../store/uiStore';

const Board1 = styled.div<{ $translate: string }>`
  transform: translate(${p => p.$translate}) rotateX(45deg) rotateZ(45deg);
  transform-style: preserve-3d;
  border-radius: 32px;
  box-shadow: 1px 1px 0 1px #f9f9fb,
  -1px 0 28px 0 rgba(34, 33, 81, 0.01),
  28px 28px 28px 0 rgba(34, 33, 81, 0.25);
  opacity: 0.05;
  pointer-events: none;

`;

export function PagePlaceholder() {
  const { placeholder } = useUiStore();
  const fen1 = useMemo<FEN>(randomFen, []);
  return <div style={{
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    {/*{placeholder && <Board1 $translate={placeholder}>*/}
    {/*  <ChessboardNotInteractive fen={fen1} />*/}
    {/*</Board1>}*/}
  </div>;

}