import { create } from 'zustand';
import { ChessclubUser } from '../../interfaces/user';
import { loggedUserFromGrpcUser } from '../../logic/loggedUserFromGrpcUser';
import { localStorageObject } from '../../helpers/localStorageObject';
import { AuthStore, SocialAuthProvider } from './AuthStoreApi';
import { clickOnLink } from '../../helpers/clickOnLink';
import { checkAuth, connectWs, disconnectWs } from './AuthStoreActions';
import { getLessonSettings } from '../../pages/EnterLessonPage/getLessonSettings';
import { useRoomsStore } from '../rooms/RoomsStore';
import { ServerApi } from '../../transport/ServerApi';
import { IProfile, IUser, Role } from '@chessclub/grpc_wrapper';
import { Lang, useUiStore } from '../uiStore';
import {
  AccessService_authenticate,
  AccessService_socialAuth,
} from '@chessclub/grpc_wrapper/src/api/services/AccessServiceExceptions';


const GUEST: ChessclubUser = {
  role: Role.GUEST,
} as ChessclubUser;

const loggedUser = localStorageObject<ChessclubUser>(
  'logged-user', null);

const savedSocialAuthProviders = localStorageObject<SocialAuthProvider[]>(
  'social-auth-providers-version-0', Object.values(SocialAuthProvider));

function getParams() {
  const params = new URLSearchParams(document.location.search);
  return {
    roomId: document.location.pathname.split('/lesson/')[1],
    socialLoginProvider: params.get('state'),
    socialLoginCode: params.get('code'),
  };
}

export const useAuthStore = create<AuthStore>((set, get) => {

  const restored = loggedUser.restore();
  const params = getParams();

  function setUserAndLogin(user: ChessclubUser) {
    set({ user, socketToken: user.session });
    user.lang && useUiStore.getState().setLang(user.lang);
    console.log('[AUTH] now user is', user);
  }

  async function setNewAuthenticatedUser(user: ChessclubUser, customHeader) {
    user.socketId = await connectWs(user.session, customHeader);
    if (user.role === Role.TEACHER || user.role === Role.STUDENT|| user.role === Role.ADMIN) {
      await useRoomsStore.getState().initRoomStore(user.id);
    }
    setUserAndLogin(user);
    loggedUser.save(user);
  }

  async function authCallback(user: IUser | keyof typeof AccessService_authenticate) {
    console.log('[AUTH] AuthStore.authCallback');
    if (typeof user === 'string') {
      const authError = { text: user };
      console.log('[AUTH] authError', authError);
      user && set({ authError });
    } else {
      const u = loggedUserFromGrpcUser(user);
      await setNewAuthenticatedUser(u, u.id.split('-')[0]);
    }
  }

  async function tryAutoLoginRegisteredUser() {
    console.log('[AUTH] AuthStore.tryAutoLoginRegisteredUser', restored);

    try {
      const user = await checkAuth(restored);
      if (typeof user !== 'string' && user.id) {
        return await setNewAuthenticatedUser(user, user.id.split('-')[0]);
      }
    } catch (e) {
      console.log(e)
    }
    setUserAndLogin(GUEST);
  }

  async function continueSocialAuth(role: Role) {
    const state = params.socialLoginProvider as SocialAuthProvider;
    const code = params.socialLoginCode;
    console.log('[AUTH] AuthStore.continueSocialAuth', state, code);
    const stateObj = state.split('-');
    const user = await ServerApi.accessService.socialAuth(
      stateObj[0], code, role, stateObj[1] as Lang);
    await handleSocialAuthResponse(user)
  }

  async function handleSocialAuthResponse(user: IUser | keyof typeof AccessService_socialAuth) {
    if (typeof user === 'string') {
      console.log("todo handle telegram auth exception:" + user)
    } else {
      await authCallback(user);
    }
  }

  window['TelegramAuthCallback'] = async (tgUser) => {
    const provider = SocialAuthProvider.TELEGRAM;
    const code =  JSON.stringify(tgUser);
    const user = await ServerApi.accessService.socialAuth(
      provider, code, Role.TEACHER, useUiStore.getState().lang);
    await handleSocialAuthResponse(user);
  };

  function getRoomIdFirstPart() {
    return document.location.pathname.split('/').pop().split('-')[0];
  }

  async function authNewStudent(name: string) {
    const user = await ServerApi.accessService.getExternalUser(name, useUiStore.getState().lang);
    await setNewAuthenticatedUser(user as any, getRoomIdFirstPart());
  }

  async function startExternalUserLogin() {
    const user = await checkAuth(restored);
    if (user && typeof user !== 'string') {
      await setNewAuthenticatedUser(user, getRoomIdFirstPart());
      return;
    }
    try {
      const lessonAuthSettings = await getLessonSettings(params.roomId);
      if (lessonAuthSettings.password || !restored?.displayName) {
        set({ lessonAuthSettings, user: GUEST });
      } else {
        await authNewStudent(restored.displayName);
      }
    } catch (e) {
      set({ user: GUEST });
    }
  }

  function initStore(): ChessclubUser | null {
    if (params.socialLoginProvider) {
      continueSocialAuth(Role.TEACHER);
    } else if (params.roomId || restored?.role === Role.EXTERNAL) {
      startExternalUserLogin();
    } else if (
      restored?.role === Role.TEACHER ||
      restored?.role === Role.STUDENT ||
      restored?.role === Role.ADMIN
    ) {
      tryAutoLoginRegisteredUser();
    } else {
      return GUEST;
    }
  }

  return {
    user: initStore(),
    socketToken: null,
    socialAuthProviders: savedSocialAuthProviders.restore(),

    async register() {

    },

    async exit() {
      loggedUser.clear();
      const session = get()?.user.session;
      session && await ServerApi.accessService.dropSession(session);
      await disconnectWs(params.roomId);
      set({ user: GUEST });
      window.location.href = '/';
    },

    async passwordAuth(email: string, password: string) {
      // debugger
      set({
        authError: {
          text: null,
          email: !email,
          password: !password,
        },
      });
      if (!email || !password)
        return;
      const user = await ServerApi.accessService.authenticate(email, password);
      await authCallback(user);
    },

    async socialAuth(provider: SocialAuthProvider) {
      const otherProviders = get().socialAuthProviders.filter(p => p !== provider);
      const socialAuthProviders = [provider, ...otherProviders];
      set({ socialAuthProviders });
      savedSocialAuthProviders.save(socialAuthProviders);
      const state = provider + '-' + useUiStore.getState().lang;
      const { url } = await ServerApi.accessService.socialAuthUrl(provider, state);
      url && clickOnLink(url);
    },

    async lessonAuth(name: string) {
      await authNewStudent(name);
    },

    async updateProfile(profile: IProfile) {
      await ServerApi.profileService.setProfile(profile);
    },

    // old

    setUser(user: ChessclubUser) {
      setNewAuthenticatedUser(user, user.id.split('-')[0]);
    },


  } as AuthStore;
});