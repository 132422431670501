import { CameraCardBase } from '../components/CameraCardBase';
import React from 'react';
import { ChessclubUser } from '../../../../../../../interfaces/user';

export function InactiveRemoteCameraCard({ user, canMove }: {
  user: ChessclubUser;
  canMove: boolean;
}) {

  return <CameraCardBase
    key={user.socketId}
    user={user}
    canMove={canMove}
    cameraEnabled={false}
    microEnabled={false}
    toggleCameraEnabled={null}
    toggleMicroEnabled={null}
  />
}