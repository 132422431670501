import React from 'react';
import styled from 'styled-components';
import { useLessonStore } from '../../../store/lesson/LessonStore';
import { OnlineButton } from '../../../components/OnlineButton/OnlineButton';
import { DemonstrationButton } from '../LessonView/buttons/DemonstrationButton';
import { KnownIcons } from '../../../components/Icon/KnownIcons';
import { SvgImage } from '../../../components/Icon/SvgImage';
import { HandeHochButton } from '../LessonView/buttons/HandeHochButton';
import { PopupMenu } from '../../../components/PopupMenu/PopupMenu';
import { LessonViewPopupMenu } from '../Toolbar/LessonViewPopupMenu';
import { LessonStatusPanelWrapper } from './LessonStatusPanelWrapper';


export function TeacherLessonStatusPanel() {
  const { lessonName, roomId, isOnline } = useLessonStore();

  if (!roomId)
    return null;

  const active = document.location.href.includes('/lesson/');

  return <LessonStatusPanelWrapper
    $active={active} to={'/lesson/' + roomId}
    $online={isOnline}
  >
    <div>
      <OnlineButton roomId={roomId}>
        <SvgImage icon={KnownIcons.power_button} withHover filled={isOnline} />
      </OnlineButton>
    </div>

    <div>Урок: {lessonName}</div>

    {isOnline && <div>
      <HandeHochButton />
      <DemonstrationButton />
      <PopupMenu popupContent={LessonViewPopupMenu}>
        <SvgImage withHover icon={KnownIcons.two_and_half_lines} />
      </PopupMenu>
    </div>}

  </LessonStatusPanelWrapper>;
}