import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useLocalization } from '../../../l10n/UseLocalization';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import { PiecesColor } from '../../../store/lesson-ui/LessonUiStoreApi';

const StyledContainer = styled.div`
  display: flex;
  gap: 3px;
`;

const StyledButton = styled.div`
  padding: 0 10px;
  height: 26px;
  border: 1.5px solid #7777;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font: var(--font-normal);
  color: var(--color-icon-main);
  &:hover {
    box-shadow: inset 0 0 6px #7777;
  }
  &.selected {
    border-color: var(--color-accent);
  }
  border-radius: 6px;
`;

export function TaskEditFirstMove() {
  const {lesson} = useLocalization();
  return <StyledContainer>
    <FirstMoveButton side={PiecesColor.WHITE}>
      {lesson.white}
    </FirstMoveButton>
    <FirstMoveButton side={PiecesColor.BLACK}>
      {lesson.black}
    </FirstMoveButton>
  </StyledContainer>
}

function FirstMoveButton(props: PropsWithChildren<{side: PiecesColor}>) {
  const {updateEditingTaskSide, editingTask} = useEditingTaskStore()
  const className = editingTask.fen.split(" ")[1] === props.side ? "selected" : "";
  const onClick = () => updateEditingTaskSide(props.side);
  return <StyledButton className={className} onClick={onClick}>
    {props.children}
  </StyledButton>
}

