import { QuestionRank } from '@chessclub/grpc_wrapper';
import { many } from '@chessclub/web-game-server/src/shared/util/math';
import React from 'react';
import styled from 'styled-components';
import { KnownIcons } from '../../../components/Icon/KnownIcons';
import { SvgImage } from '../../../components/Icon/SvgImage';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';

const StyledContainer = styled.div`
  display: flex;
  gap: 3px;
`;

const StyledRank = styled.div`
  width: 26px;
  height: 26px;
  border: 1.5px solid #7777;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font: var(--font-normal);
  cursor: pointer;
  color: var(--color-icon-main);

  &:hover {
    box-shadow: inset 0 0 6px #7777;
  }

  &.selected {
    border-color: var(--color-accent);
  }

  border-radius: 6px;
`;


export function TaskEditRank() {
  const { editingTask, updateEditingTaskData } = useEditingTaskStore();
  const cur = editingTask?.rank;
  return <StyledContainer>
    {many(11, i => i).map(rank => {
      return <StyledRank
        key={rank}
        onClick={() => updateEditingTaskData({ rank: rank as QuestionRank})}
        className={cur === rank ? 'selected' : ''}
      >
        {rank || <SvgImage size={24} icon={KnownIcons.cross} />}
      </StyledRank>;
    })}
  </StyledContainer>;
}

