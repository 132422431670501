import Dexie from 'dexie';
import { ITask, RoomId, TaskId, UserId } from '@chessclub/grpc_wrapper';
import { IRoom } from '../../interfaces/room';

const dexie = new Dexie('chessclub--draft-tasks');

dexie.version(1).stores({
  draftTasks: '++id, roomId',
});

const draftTasks: Dexie.Table
  // @ts-ignore
  = dexie.draftTasks;


export const draftTasksDb = {

  async getDraftTasks(roomId: RoomId) : Promise<ITask[]> {
    return draftTasks
      .where('roomId').equals(roomId)
      .toArray();
  },

  async saveDraftTask(task: ITask) {
    await draftTasks.put(task);
  },

  async deleteDraftTask(id: TaskId) {
    await draftTasks.delete(id);
  },
}