import React from 'react';
import { atlas } from './atlas';
import { boardSettings } from './boardSettings';
import { HasTaskId, LocalChessboard } from '@chessclub/web-game-server/index';
import { GameState } from '@chessclub/web-game-server';
import { QuestionBase } from '@chessclub/grpc_wrapper';
import { GameApi } from '@chessclub/web-game-server/src/client/hooks/useCentrifugeChessboard';
import { useModalStore } from '../../store/modalStore';
import { useChessboardStore } from '../../store/chessboard/ChessboardStore';
import { stockfish } from '../../app/workers';

export type ChessboardWithLocalLogicProps = {
  onGameApiChanged: (gameApi: GameApi) => void;
  onGameStateChanged: (state: GameState) => void;
  reversed?: boolean;
  editMode?: boolean;
  task: QuestionBase & HasTaskId
};

export function ChessboardWithLocalLogic(props: ChessboardWithLocalLogicProps) {
  const {markerHue, interactivityMode} = useChessboardStore()
  const {modal, popup} = useModalStore()
  const {reversed, onGameStateChanged, onGameApiChanged, task} = props;

  if (!task)
    return null;
  return <>
    <LocalChessboard
      interactivityMode={interactivityMode}
      onGameStateChanged={onGameStateChanged}
      onGameApiChanged={onGameApiChanged}
      stockfish={stockfish}
      task={task}
      editMode={props.editMode}
      params={{
        ...boardSettings,
        atlas,
        rotated: reversed,
        blocked: !!modal || !!popup,
        markerHue
      }}
    />
  </>;
}
