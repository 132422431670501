import { useEditingTaskStore } from '../../../../store/editing-task/EditingTaskStore';
import { FenButtonRaw } from './FenButtonRaw';
import React from 'react';

export function FenButton({ fen }: { fen }) {
  const { updateEditingTaskData, editingTask, requestUpdate } = useEditingTaskStore();
  return <FenButtonRaw
    onClick={() => {
      updateEditingTaskData({ fen, moves: [], tools: {} })
      requestUpdate();
    }}
    fen={fen}
    active={editingTask.fen === fen}
  />;
}