import { MovesTable } from '../../../components/MovesPanel/MovesTable';
import React from 'react';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import styled from 'styled-components';
import { MovesChain } from '../../../components/MovesPanel/MovesChain';


const StyledMoves = styled.div`
  max-width: 300px;
`

export function TaskEditMovesTable() {
  const { editingTask } = useEditingTaskStore();
  // debugger
  return <StyledMoves>
      <MovesChain moves={editingTask.fullMoveDescriptions} />
  </StyledMoves>;
}