import React from 'react';
import styled from 'styled-components';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { ContentContainer } from '../../components/ContentContainer';
import { MaterialsPageToolbarSelector } from './toolbar/MaterialsPageToolbarSelector';
import { MaterialsPageSearchField } from './MaterialsPageSearchField';
import { MaterialsPageTopics } from './MaterialsPageTopics';
import { useEditingTaskStore } from '../../store/editing-task/EditingTaskStore';
import { TaskEditView } from '../../parts/TaskEditView/TaskEditView';
import { useMaterialsStore } from '../../store/materials/MaterialsStore';
import { useEditingTopicStore } from '../../store/editing-topic/EditingTopicStore';
import { TopicEditView } from '../../parts/TopicEditView/TopicEditView';
import { MaterialsList } from '../../parts/MaterialsEditView/MaterialsList';
import { newMaterial } from './newMaterial';
import { TopicType } from '@chessclub/grpc_wrapper/src/api/types/ITopic';
import { IMaterial } from '@chessclub/grpc_wrapper/src/api/types/IMaterial';
import { useLessonStore } from '../../store/lesson/LessonStore';
import { useTasksStore } from '../../store/tasks/TasksStore';
import { compareTasks } from '../../logic/compareTasks';
import { ApplyCancelToolbarVariant } from '../../parts/ApplyCancelToolbarVariant';
import { MaterialsPageTopicEditToolbarVariant } from './toolbar/MaterialsPageTopicEditToolbarVariant';
import { MaterialsPageTaskEditToolbarVariant } from './toolbar/MaterialsPageTaskEditToolbarVariant';
import { EditViewHeader } from '../../parts/EditViewHeader';
import {
  KnowledgeEditingTaskToolbar
} from '../AdminDashboard/AdminSubpageKnowledge/toolbar/KnowledgeEditingTaskToolbar';

const MaterialsLayout = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: 0fr;
  gap: 30px;
  padding-top: var(--top-gap);
`;

export function MaterialsPage() {
  const {materials} = useMaterialsStore();
  const {editingTask} = useEditingTaskStore();
  const placeholder = !editingTask && (!materials || materials?.length < 6) ? "70%,50%" : null;
  return <PageTemplate placeholder={placeholder}>
    <ContentContainer
      title={'Materials'}
      toolbar={<MaterialsPageToolbarSelector />}
    >
      <MaterialsPageContentSelector />
    </ContentContainer>
  </PageTemplate>;
}

function MaterialsPageContentSelector() {
  const { editingTask } = useEditingTaskStore();
  return editingTask ? <TaskEditView >
    <EditViewHeader text={"Edit material"}>
      <MaterialsPageTaskEditToolbarVariant/>
    </EditViewHeader>

  </TaskEditView> : <MaterialsPageContent />;
}

function MaterialsPageContent() {
  return <MaterialsLayout>
    <div style={{ height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <MaterialsPageSearchField />
      <MaterialsPageTopics />
    </div>
    <div style={{ height: 'calc(100vh - 250px)' }}>
      <MaterialsPageRightPanelContentSelector />
    </div>
  </MaterialsLayout>;
}

function MaterialsPageRightPanelContentSelector() {
  const { selectedTopic } = useMaterialsStore();
  const { editingTopic } = useEditingTopicStore();

  if (editingTopic)
    return <TopicEditView>
        <MaterialsPageTopicEditToolbarVariant />
  </TopicEditView>;

  if (!selectedTopic)
    return null;

  return <Materials/>
}

function Materials() {
  const { selectedTopic, materials, deleteMaterial } = useMaterialsStore();
  const { monitoringStudentId, roomId, teacherId } = useLessonStore();
  const { addOrRemoveMaterial, tasks } = useTasksStore();

  function use(mat: IMaterial) {
    addOrRemoveMaterial(
      mat,
      roomId,
      monitoringStudentId || teacherId,
    );
  }

  const isEditable = selectedTopic.type === TopicType.MATERIAL;
  return <MaterialsList
    onDelete={isEditable ? deleteMaterial : null}
    materials={materials}
    factory={isEditable ? newMaterial: null}
    onClick={use}
    accent={mat => !!tasks.find(m => compareTasks(m, mat))}
  />;
}