import styled from 'styled-components';

export const Stick = styled.span<{
  horizontal?: boolean
}>`
  display: inline-block;
  width: 1px;
  height: 20px;
  margin: 0 10px;
  ${({ horizontal }) => `transform:rotate(${horizontal ? '90deg' : '0'});`}
  background-color: ${({ theme }) => `${theme.currentTheme.switchOffBorderColor}`};
`;