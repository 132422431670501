import React from "react";
import styled from 'styled-components';
import { ChessboardSvg } from '../../../../components/Chessboard/ChessboardSvg';
import { useLessonUiStore } from '../../../../store/lesson-ui/LessonUiStoreImpl';
import { useTaskToDisplay } from '../../../../hooks/useTaskToDisplay';
import { useTasksStore } from '../../../../store/tasks/TasksStore';
import { SvgImage } from '../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../components/Icon/KnownIcons';
import { QuestionBase } from '@chessclub/grpc_wrapper';
import { useEditingTaskStore } from '../../../../store/editing-task/EditingTaskStore';

const CurrentQuestionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px;
  background: var(--color-hover);
  border-radius: 6px;
  & .left-part {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 32px 1fr 48px;
  }
  & .chessboard {
    display: flex;
  }

  & .left-part .buttons {
    display: flex;
    padding: 8px 10px;
  }
`;

export function MovesTabCurrentQuestion() {

  const { mouseOverMove } = useLessonUiStore();
  const { taskToDisplay } = useTaskToDisplay();
  const { currentTaskGameState, currentTaskGameApi } = useTasksStore();
  const { startEditTask } = useEditingTaskStore();

  if (!taskToDisplay)
    return null;

  const fen = mouseOverMove?.fen || currentTaskGameState?.fen || taskToDisplay.fen;

  const markers = currentTaskGameState?.markers && (mouseOverMove ?
    currentTaskGameState?.markers[mouseOverMove.index]: currentTaskGameState?.markers[-1]);

  const activateStartPosition = () => {
    currentTaskGameApi.setStepIndex(-1);
    currentTaskGameApi.requestUpdate();
  };
  const editTask = () => startEditTask(taskToDisplay);

  // return
  // <CurrentQuestionWrapper >
      {/*<div className='left-part'>*/}
      {/*    <div>1</div>*/}
      {/*    <div>2</div>*/}
      {/*    <div className='buttons'>*/}
      {/*      <SvgImage tooltip={"Редактировать"} icon={KnownIcons.pencil} withHover onClick={editTask}/>*/}
      {/*      <SvgImage tooltip={"Сбросить"} icon={KnownIcons.reverse} withHover />*/}
      {/*      <SvgImage tooltip={"Сохранить"} icon={KnownIcons.diskette} withHover />*/}
      {/*    </div>*/}
      {/*</div>*/}
      {/*<div className='chessboard' onClick={activateStartPosition}>*/}
     return   <ChessboardSvg fen={fen} markers={markers} />
      {/*</div>*/}
  // </CurrentQuestionWrapper>
}