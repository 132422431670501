import { create } from 'zustand';
import {
  LayoutSlots,
  LessonTabs,
  LessonUiStore,
  LessonViewMode,
  MovesDisplayMode,
  TabsLayout,
} from './LessonUiStoreApi';
import { localStorageObject } from '../../helpers/localStorageObject';
import { TaskId } from '@chessclub/grpc_wrapper';
import { createDefaultLessonLayout } from './createDefaultLessonLayout';
import { FullMoveDescription } from '@chessclub/web-game-server/src/shared/core/calcMoves';

const lessonViewMode = localStorageObject<LessonViewMode>('lesson-view-mode', LessonViewMode.STANDARD);
const movesDisplayMode = localStorageObject<MovesDisplayMode>('lesson-moves-display-mode', MovesDisplayMode.TABLE);
const tabsLayout = localStorageObject<TabsLayout>('tabs-layout', createDefaultLessonLayout());

export const useLessonUiStore = create<LessonUiStore>((
  set,
  get,
) => {

  return {

    mouseOverMove: null,
    tabsLayout: tabsLayout.restore(),
    view: lessonViewMode.restore(),
    movesMode: movesDisplayMode.restore(),
    boardRotations: {},

    changeLayout(targetSlot: LayoutSlots, tab: LessonTabs) {
      const newLayout: TabsLayout = {...get().tabsLayout};
      const sourceSlot = newLayout.tabs[tab];
      newLayout.slots[sourceSlot] = newLayout.slots[sourceSlot].filter(t => t !== tab);
      newLayout.slots[targetSlot].push(tab);
      newLayout.tabs[tab] = targetSlot;
      tabsLayout.save(newLayout)
      set({tabsLayout: newLayout});
    },

    setMouseOverStep(mouseOverMove: FullMoveDescription & {index: number}) {
      set({mouseOverMove})
    },

    toggleTab(tab: LessonTabs) {
      const layout = get().tabsLayout;
      const slot = layout.tabs[tab];
      layout.slots[slot].forEach(t => t !== tab && (layout.visible[t] = false));
      layout.visible[tab] = !layout.visible[tab];
      let newLayout = JSON.parse(JSON.stringify(layout));
      tabsLayout.save(newLayout)
      set({tabsLayout: newLayout});
    },

    rotateBoard(id: TaskId) {
      const boardRotations = get().boardRotations;
      set({ boardRotations: { ...boardRotations, [id]: !boardRotations[id] } });
    },

    setView: (view: LessonViewMode) => {
      lessonViewMode.save(view);
      set({ view });
    },

    setMovesDisplayMode(movesMode: MovesDisplayMode) {
      movesDisplayMode.save(movesMode);
      set({ movesMode });
    },
  };
});
