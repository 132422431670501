import React  from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { ToolbarButton } from '../../components/ContentContainer/ToolbarButton';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import {  useRoomsStore } from '../../store/rooms/RoomsStore';
import {  StyledInputWrapper} from '../../components/Modal/common';
import Input from '../../components/low-level-components/Input';
import { SvgImage } from '../../components/Icon/SvgImage';
import styled from 'styled-components';
import { UsersList } from './UsersList';
import { useAuthStore } from '../../store/auth/AuthStore';
import { useEditingRoomStore } from '../../store/editing-room/EditingRoomStore';
import { StudentViewSettings } from './StudentViewSettings';
import { RoomEditToolbarVariant } from './RoomEditToolbarVariant';
import { EditViewHeader } from '../EditViewHeader';


const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 600px 1fr 1fr;
  gap: 50px;
`

export function RoomEditView() {
  const { updateEditingRoom, editingRoom} = useEditingRoomStore();
  const {user} = useAuthStore();



  const link = `${document.location.origin}/lesson/${editingRoom.id}`;

  return <PageTemplate>
    <ContentContainer title={!editingRoom.id ? "New room" :"Edit room"} toolbar={<RoomEditToolbarVariant/>}>

      <EditViewHeader text={"Edit Room"}>
        <RoomEditToolbarVariant/>
      </EditViewHeader>
      <Wrapper>
      <div>
          <StyledInputWrapper>
            <Input
              label='Название'
              fullWidth
              value={editingRoom.name || ''}
              onChange={(e) => updateEditingRoom({ name: e.target.value })}
            />
          </StyledInputWrapper>

          <StyledInputWrapper>
            <Input
              label='Описание'
              fullWidth
              rows={4}
              value={editingRoom.description || ''}
              onChange={(e) => updateEditingRoom({ description: e.target.value })}
            />
          </StyledInputWrapper>

          {/*<StyledDivider />*/}
          {/* Room Link  */}
          {editingRoom.id && (
            <StyledInputWrapper>
              <Input endAdornment={<SvgImage
                style={{margin: 5}}
                withHover
                icon={KnownIcons.copy}
                onClick={() => navigator.clipboard.writeText(link)}
              />} label='Ссылка' disabled fullWidth value={link} />
              {/*<IconButton>*/}

              {/*</IconButton>*/}
            </StyledInputWrapper>
          )}
          {/* Password Input */}
          <StyledInputWrapper>
            <Input
              autoComplete={'off'}
              label='Пароль (не обязательно)'
              value={editingRoom.password || ''}
              onChange={(e) => updateEditingRoom({password:  e.target.value})}
              fullWidth
            />
          </StyledInputWrapper>
        <StudentViewSettings settings={editingRoom.settings}/>
      </div>
        <UsersList users={[user,user,user]}/>
        <UsersList users={[user,user,user,user,user]}/>
      </Wrapper>


    </ContentContainer>
  </PageTemplate>;

}
