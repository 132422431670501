import React, { FormEvent, PropsWithChildren } from 'react';
import { useTextEditor } from '../../hooks/useTextEditor';
import TextEditor from '../../components/TextEditor/TextEditor';
import Input from '../../components/low-level-components/Input';
import { InputAdornment } from '@material-ui/core';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import { useEditingTopicStore } from '../../store/editing-topic/EditingTopicStore';

export function TopicEditView(props: PropsWithChildren<{}>) {

  const { editingTopic, updateEditingTopic } = useEditingTopicStore();

  const { editor, focus, handleBlur, handleFocus, readFileAsDataURL, handlePointerUp } =
    useTextEditor('Описание', editingTopic.description, (str) => {
      editingTopic && updateEditingTopic({
        description: str,
      });
    });

  // editor && editor.setEditable(!!editingTopic);


  return <div>
    {props.children}
    <div style={{ display: 'flex', flexWrap: 'wrap', padding:5, boxSizing: 'border-box' }}>
      {Object.entries(KnownIcons).map(([key, icon]) => {
        return <SvgImage
          icon={icon}
          key={key}
          active={editingTopic?.customData?.icon === key}
          onClick={() => updateEditingTopic({customData:{icon:key}})}
        />;
      })}
    </div>
    <Input
      autoFocus
      disabled={!editingTopic}
      fullWidth
      label='Название'
      onChange={(e: FormEvent<HTMLInputElement>) => {
        updateEditingTopic({
          name: e.currentTarget.value,
        });
      }}
      value={editingTopic?.name}
      endAdornment={
        editingTopic?.name ? (
          <InputAdornment
            onClick={() => {
              updateEditingTopic({
                name: '',
              });
            }}
            style={{ paddingRight: '2px' }}
            position='start'
          >
            <SvgImage icon={KnownIcons.cross} withHover />
          </InputAdornment>
        ) : null
      }
    />

    <TextEditor
      editor={editor}
      focus={focus}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
      readFileAsDataURL={readFileAsDataURL}
      handlePointerUp={handlePointerUp}
      withMenu={true}
    />



  </div>;
}

