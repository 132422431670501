import React from 'react';
import styled from 'styled-components';

export const StyledMiddle = styled.div`
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 4px 0px 4px 10px;
  overflow-x: auto;
`;

export const StyledButton = styled.span<{ chessboardActionType?; interfaceClr?: string }>`
  cursor: pointer;
  box-sizing: border-box;
  font: ${({ theme }) => theme.fonts.small};
  color: ${({ theme, chessboardActionType, interfaceClr }) =>
    theme.currentTheme[chessboardActionType === 'erase' ? interfaceClr : 'iconMain']};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding: 0.25rem;
  //margin-right: 10px;

  &:last-child {
    margin-right: 0px;
  }

  img {
    margin-right: 10px;
  }

  img:last-child {
    margin-right: 0px;
  }

  &:hover {
    background: ${({ theme }) => theme.currentTheme.hover};
  }

  font {
    // padding-right: 8px;
  }
`;


export const StyledChessTools = styled.div<{
  noMargin?: boolean;
  isOnline?: boolean;
  longVariant?: boolean;
  active: boolean;
  pathname: string;
  isEditQuestionModeOn?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;

  border-bottom: 1px solid ${({ theme }) => theme.currentTheme.iconLight};
  position: relative;
  width: 100%;
  padding-left: 3px;

  ${({ active, isEditQuestionModeOn }) =>
    !active &&
    !isEditQuestionModeOn &&
    `opacity: 0.5;
      &::after {
      position: absolute;
      content: '';
      background: #ffffff00;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 23;
    }
  `}
  
`;

export const StyledLeft = styled.div<{
  noMargin?: boolean;
  isOnline?: boolean;
  longVariant?: boolean;
  pathname: string;
}>`
  // width: 175px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  ${({ noMargin, theme }) =>
    noMargin ? '' : `padding: 13px 9px 13px 16px;border-right: 1px solid ${theme.currentTheme.iconLight}`};
  
  ${({ pathname }) => pathname === '/materials' && `& .title { display: block !important; }`}

  `;

export const StyledFigures = styled.span`
  /* z-index: 9999; */
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: wrap;

  & > * {
    margin-right: 10px;
  }

  & > *:last-child {
    margin-right: 0px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledRight = styled.div`
  // width: 54px;
  // margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  // padding-right: 19px;
`;




export const StyledToolName = styled.span`
  padding-right: 8px;
  margin-left: 0.3rem;
  @media (max-width: 1024px) {
    display: none;
  }
`;